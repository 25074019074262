li.ant-menu-item {
  width: 100% !important;
  height: 50px !important;
  margin-inline: 0 !important;
  margin-block: 0 !important;
  border-radius: 0 !important;
  /* border-left: 4px solid #fff0; */
}

li.ant-menu-item.ant-menu-item-selected {
  background-color: #F9F0FF;
  /* border-left: 4px solid #4085ee; */
}

li.ant-menu-item.ant-menu-item-selected > .ant-menu-title-content > a > span,
li.ant-menu-item.ant-menu-item-selected > .ant-menu-title-content > a > i
{
  color: #722ED1 !important;
}