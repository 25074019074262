.agent-card-item .ant-card-hoverable {
  cursor: default;
  border-color: #DCDCDC;
  /*box-shadow: unset;*/
  transition: box-shadow 0.2s, border-color 0.2s;
}

.agent-card-item .ant-card-hoverable:hover {
  /*cursor: pointer;*/
  border-color: #DF053A;
  /*font-weight: 700 !important;*/
  box-shadow: 0 0 16px rgba(223, 4, 58, 0.2);
  transition: box-shadow 0.2s, border-color 0.2s;
}

.agent-card-item .ant-card-body {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 12px 12px;
}
.agent-card-add-item .ant-card-body {
  cursor: pointer;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 12px 12px;
}
