/* .cke_inner .cke_reset{
    height:90% !important;
} */

.gx-main-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.dashboard-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px;
  /*max-width: calc(100vw - 250px);*/
  /*max-height: calc(100vh - 178px);*/
}

/* TODO: 화면 사이즈 모니터에 최적화 */
.viewer {
  position: relative;
  /* width: 100%; */
  height: 100%;
  padding: 20px;

  /*max-width: calc(100vw - 250px);*/
  /*max-height: calc(100vh - 178px);*/
  /* display: flex;
  flex-direction: column; */
}

.stream-header {
  height: 60px;
  /* gap: 0px; */
  /* border-radius: 8px 8px 0px 0px; */
  border: 1px solid #DCDCDC;
  background-color: #fff;
  display: flex;
  align-items: center;


}

.stream-contents {
  height: calc(100%);
  position: relative;

}

.cursor-none {
  cursor: none;
}

.video-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

}
.app-stream-video {
  /*width: 100%;*/
  /*height: 100%;*/
}
