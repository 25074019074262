.gx-app-login-wrap {
  height: 100%;
  min-width: 400px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  overflow-x: hidden;
  background-image: url("/public/assets/images/login-background.jpeg");
  background-size: cover;
}
.gx-app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;
}
.gx-app-login-container .gx-loader-view {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.gx-app-login-main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  font-size: 14px;
  overflow: hidden;
  align-content: center;
  padding: 100px 0 110px 0;
}

.gx-app-login-content {
  padding: 35px 35px 20px;
  /* width: 60%; */
}
.gx-app-login-content .ant-input {
  background-color: #f5f5f5;
}
.gx-app-login-content .ant-input:focus {
  box-shadow: none;
  /*border-color: #038fde;*/
}
.gx-app-login-content .gx-btn {
  padding: 6px 35px !important;
  height: auto;
}
.gx-app-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-app-login-content .ant-form-item-children {
  display: block;
}
.gx-app-login-header {
  margin-bottom: 30px;
}
.gx-app-logo-content {
  color: #ffffff;
  padding: 5px 35px 40px;
  width: 40%;
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-app-logo-content > * {
  position: relative;
  z-index: 2;
}
.gx-app-logo-content h1 {
  color: #ffffff;
}
.gx-app-logo-content-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.gx-app-logo-content-bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  background-color: rgb(138, 118, 219);
}
.gx-app-logo-content-bg img {
  width: 100%;
  height: 100%;
}
.gx-app-logo-wid {
  margin-bottom: auto;
}
.gx-app-social-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.gx-app-social-block .gx-social-link,
.gx-app-social-block .gx-social-link li {
  margin: 0;
}
.gx-app-social-block .gx-social-link span {
  border: solid 1px #038fde;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #038fde;
}
.gx-app-social-block .gx-social-link span:hover,
.gx-app-social-block .gx-social-link span:focus {
  color: #ffffff;
  background-color: #038fde;
}
.gx-app-social-block p {
  margin-bottom: 0;
}
.gx-signin-form .ant-form-item-control-input {
  min-height: 40px;
}


.sns-login-button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 5px;
}
.sns-login-button > button {
  width: 100%;
}
.remember-checked span {
  color: #7E7E7E;
}
.remember-checked .ant-checkbox {
  top: -2px;
}
.ant-form-horizontal > .ant-form-item > .ant-row, .ant-form-horizontal > .ant-row {
  border-top: 0;
}
.ant-form-horizontal > .ant-form-item > .ant-row:last-child, .ant-form-horizontal > .ant-row:last-child {
  border-bottom: 0;
}
.login-features {
  display: flex;
  margin-top: 4px;
}
.login-features .ant-space {
  display: flex;
  justify-content: flex-end;
}
.login-features > .ant-space-item:first-child {
  flex: 1;
}
.gx-login-form-forgot {
  color: #7F7F7F;
}

.signin-button {
  margin-top: 46px;
}

/* iPhone SE - Landscape */
@media screen and (max-height: 430px) and (orientation : landscape) {

  .gx-app-login-main-content {
    padding: 5px 55px 5px;
  }
  .signin-button {
    margin-top: 0;
  }

  .gx-app-login-content {
    padding: 5px 35px 5px;
  }

  .gx-app-logo-content {
    padding: 15px 105px 15px;
  }

  .gx-app-login-main-content {
    max-width: 580px;
  }

  .gx-app-login-container {
    display: flex;
    justify-content: center;
  }

  .gx-app-login-content {
    width: 100%;
  }
  .gx-app-logo-content {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .gx-app-login-main-content {
    padding: 20px 20px 10px;
  }
  .gx-app-logo-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
  .gx-app-login-container {
    padding-bottom: 20px ;
  }
  .gx-app-login-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
  .gx-app-login-header {
    margin-bottom: 15px;
  }
  .gx-app-logo-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
