.vlink-modal .ant-modal-content {
  padding: 0;
}

.vlink-modal .ant-modal-close {
  top: 20px;
}

.vlink-modal .popup-button-row .ant-btn {
    height: 35px;
    font-size: 16px;
}