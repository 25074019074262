.dot {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  background: radial-gradient(circle, rgba(255, 0, 0, 0.3) 10%,red 15%,  rgba(255, 0, 0, 0.2) 30%, transparent 80%);
  border-radius: 100%;
  /* border: 1px solid black; */
  transform: translate(-50%, -50%);
  z-index: 999;
  pointer-events: none;
}
.dot.hovered {
  display: none;
}
