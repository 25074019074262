.select-user-container .ant-segmented-group {
    /*flex-wrap: wrap;*/
}
.select-user-container .ant-segmented-item {
    padding: 8px;
}
.user-list th {
  background-color: #D9D9D9 !important;
}

.user-list th.ant-table-cell.ant-table-column-has-sorters::before {
  background-color: #B9B8B9 !important;
}
.user-list li.ant-pagination-item-active {
  background-color: transparent;
  border: 0;
}
