.settings-menu .ant-menu-submenu .ant-menu-submenu-title {
  width: 100%;
  height: 52px;
  line-height: 52px;
  border-radius: 0;
  margin: 0;
  margin-inline: 0;
  margin-block: 0;
  padding: 0 !important;
  font-size: 16px;
  font-weight: 700;
}

.settings-menu .ant-menu-submenu .ant-menu-submenu-title .ant-menu-title-content {
  padding-left: 24px;
}



.settings-menu .ant-menu-submenu .ant-menu-submenu-title:before {
  content: "";
  width: 4px;
  height: 100%;
  background-color: transparent;
}

.settings-menu .ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title:before {
 
  background-color: #DF043A;
}



.settings-menu .ant-menu-submenu {
  border-bottom: 1px solid #EFEFEF;
  border-radius: 0;

}

.settings-menu .ant-menu-submenu .ant-menu-sub .ant-menu-item {
  padding: 0 !important;
  color: #4F4F4F;
  height: 42px !important;
}

.settings-menu .ant-menu-submenu .ant-menu-sub .ant-menu-item.ant-menu-item-selected  {
  background-color: #FFE9EF;
  color: #181818;
  font-weight: 400;
}

.settings-menu .ant-menu-submenu .ant-menu-sub .ant-menu-item .ant-menu-title-content {

  padding-left: 28px;
}

.settings-card { 
  /* height: 100%; */
}

.settings-card .ant-card-head, .settings-card .ant-card-body {
  padding-left: 40px;
}

.settings-card .ant-card-head {
  font-size: 24px;
  height: 70px;
}

.settings-card .ant-card-body > div > div {
  border-bottom: 1px solid #EFEFEF;
}

.settings-card .ant-card-body > div > div:last-child {
  border-bottom: none;
}


.pincode-input-container
{
  .pincode-input-text
  {
    padding:0 !important;
    margin:0 2px;
    text-align:center;
    border: 1px solid #DCDCDC;
    background: transparent;
    width: 50px;
    height: 50px;
  }
 
  input:valid
  {
    border: 1px solid #4F4F4F;
    background: transparent;
    box-shadow: #DCDCDC 0px 0px 0px 1px
  }

  input:focus
  {
    outline:none;
    box-shadow:none;
    border-color: #4F4F4F;
    border-width: 2px;
  }

  input[type='password'] {
    font-family: Verdana;
    letter-spacing: 0.125em;
  }
}

.pincode-input-container.invalid {
  input {
    border-color: #FD85A4;
    border-width: 2px;
  }
}




.user-list th {
  background-color: #D9D9D9 !important;
}

.user-list th.ant-table-cell::before {
  background-color: #B9B8B9 !important;
}
.user-list li.ant-pagination-item-active {
  background-color: transparent;
  border: 0;
}

