.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}


.ant-form-item .ant-form-item-label > label::after{
    content: "";

}

.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1;
    content: "*";
}



.ant-form-horizontal .ant-form-item {
    margin-bottom: 0;
}

.ant-form-horizontal > .ant-form-item > .ant-row, .ant-form-horizontal > .ant-row {
    /* border-top: 1px solid lightgray; */
}
.ant-form-horizontal > .ant-form-item > .ant-row:last-child,
.ant-form-horizontal > .ant-row:last-child {
    /* border-bottom: 1px solid lightgray; */

}

.ant-form-horizontal .ant-form-item .ant-row .ant-col.ant-form-item-label {
    /* background-color: #d3d3d375; */
    text-align: start;
    padding-left: 10px;
    display: flex;
    align-items: center;
}

.ant-form-horizontal .ant-form-item .ant-row .ant-col.ant-form-item-control {
    padding: 4px;
    justify-content: center;
}


.ant-form-horizontal .ant-form-item .ant-row .ant-col.ant-form-item-label > label{
    /* height: 40px; */

}


.ant-form-horizontal .row-has-2cols > .ant-col {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
}

.ant-form-horizontal .row-has-2cols > .ant-col > .ant-form-item > .ant-row > .ant-col.ant-form-item-label {
    flex: 0 0 33.33%;
    max-width: 33.33%;
} 


.ant-form-horizontal .row-has-1cols > .ant-form-item,
.ant-form-horizontal .row-has-1cols > .ant-col {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
}

.ant-form-horizontal .row-has-1cols > .ant-form-item > .ant-row > .ant-col.ant-form-item-label,
.ant-form-horizontal .row-has-1cols > .ant-col > .ant-form-item > .ant-row > .ant-col.ant-form-item-label {
    flex: 0 0 25%;
    max-width: 25%;
    font-weight: 700;
    color: #181818;

    label {
        font-size: 16px;

    }
}

.ant-form-horizontal input:read-only {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.ant-form-horizontal .button-area {
    border: none !important;
    justify-content: flex-end;
    margin: 10px 0; 
}


/*  */
/* for vertical */
/*  */

.ant-form.ant-form-vertical {
    position: relative;
    /* border: 1px solid #F0F0F0; */
    /* padding: 10px 0px; */
    background-color: #fff;
}

.ant-form.ant-form-vertical > .ant-row {
    gap: 50px;
    padding: 10px 0;
}

.ant-form-vertical .ant-row .ant-col .ant-form-item {
    margin-bottom: 0;
}


.ant-form.ant-form-vertical .button-area{
   position: absolute;
   right: 0;
   bottom: 10px;

   display: flex;
   gap: 10px;
}


.ant-form-vertical .row-has-3cols > .ant-col {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
}

.ant-form-vertical .row-has-3cols > .ant-col > .ant-form-item > .ant-row > .ant-col.ant-form-item-label {
    flex: 0 0 100%;
    max-width: 100%;
} 

.ant-form-vertical .row-has-2cols > .ant-col {
    display: block;
    flex: 0 0 33.33%;
    max-width: 33.33%;
}

.ant-form-vertical .row-has-2cols > .ant-col > .ant-form-item > .ant-row > .ant-col.ant-form-item-label {
    flex: 0 0 100%;
    max-width: 100%;
} 


.ant-form-vertical .ant-row > .ant-col > .ant-form-item > .ant-row > .ant-col.ant-form-item-label {
    flex: 0 0 100%;
    max-width: 100%;
} 
