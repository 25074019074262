@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 700;
    font-display: normal;
    src: url('SpoqaHanSansNeo-Bold.eot?#iefix') format('embedded-opentype'),
    url('SpoqaHanSansNeo-Bold.woff2') format('woff2'),
    url('SpoqaHanSansNeo-Bold.woff') format('woff'),
    url('SpoqaHanSansNeo-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 500;
    font-display: normal;
    src: url('SpoqaHanSansNeo-Medium.eot?#iefix') format('embedded-opentype'),
    url('SpoqaHanSansNeo-Medium.woff2') format('woff2'),
    url('SpoqaHanSansNeo-Medium.woff') format('woff'),
    url('SpoqaHanSansNeo-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 400;
    font-display: normal;
    src: url('SpoqaHanSansNeo-Regular.eot?#iefix') format('embedded-opentype'),
    url('SpoqaHanSansNeo-Regular.woff2') format('woff2'),
    url('SpoqaHanSansNeo-Regular.woff') format('woff'),
    url('SpoqaHanSansNeo-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 300;
    font-display: normal;
    src: url('SpoqaHanSansNeo-Light.eot?#iefix') format('embedded-opentype'),
    url('SpoqaHanSansNeo-Light.woff2') format('woff2'),
    url('SpoqaHanSansNeo-Light.woff') format('woff'),
    url('SpoqaHanSansNeo-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 100;
    font-style: normal;
    src: url('SpoqaHanSansNeo-Thin.eot?#iefix') format('embedded-opentype'),
    url('SpoqaHanSansNeo-Thin.woff2') format('woff2'),
    url('SpoqaHanSansNeo-Thin.woff') format('woff'),
    url('SpoqaHanSansNeo-Thin.ttf') format('truetype');
}

/** {*/
/*    font-family: 'Spoqa Han Sans Neo';*/
/*}*/
