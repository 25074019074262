
.agent-tabs .ant-tabs-tab.ant-tabs-tab {
  /*padding: 8px 12px;*/
  padding: 0;
  margin: 0 !important;
}

.agent-tabs .ant-tabs-nav {
  margin: 0 !important;
}
.agent-tabs .ant-tabs-tab.ant-tabs-tab-active {
  /*background-color: #ffffff;*/
  /*border-top: 3px solid #DF053A;*/
  /*border-top-left-radius: 4px;*/
}
.agent-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  /*top: -1.5px;*/
  /*position: relative;*/
}
.ant-tabs .ant-tabs-tab-btn:focus-visible {
  outline: unset !important;
}
