.outline-button {
  cursor: pointer;
  border: 1px solid #B9B9B9;
  color: #181818;
  /*box-shadow: unset;*/
  /*transition: box-shadow 0.2s, border-color 0.2s;*/
}

.outline-button:hover:not(:disabled) {
  cursor: pointer;
  border: 1px solid #DF053A;
  color: #DF053A !important;
  /*box-shadow: unset;*/
  /*transition: box-shadow 0.2s, border-color 0.2s;*/
}
