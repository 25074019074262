.ant-tabs.ant-tabs-top {
    /*margin-left: 25px;*/
}

.ant-tabs-tab > .ant-tabs-tab-btn {
    font-size: 14px;
}

.ant-space-item > .menu-tabs {

}

.menu-tabs > .ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap {
  justify-content: flex-end;
}

.user-profile-right {
  display: flex;
}
@media screen and (max-width: 960px) {
  .menu-tabs > .ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap {
    justify-content: flex-start;
  }
  .user-profile-right {
    display: none;
  }
}
