.user-card-item .ant-card-hoverable {
  cursor: default;
  border-color: #DCDCDC;
  /*box-shadow: unset;*/
  transition: box-shadow 0.2s, border-color 0.2s;
}

.user-card-item .ant-card-hoverable:hover {
  /*cursor: pointer;*/
  /*border-color: #DF053A;*/
  /*box-shadow: unset;*/
  transition: box-shadow 0.2s, border-color 0.2s;
}

.user-card-item .ant-card-body {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0 12px 0;
}
