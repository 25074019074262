.pincode-input-container-popup
{
  .pincode-input-text
  {
    padding:0 !important;
    margin:0 2px;
    text-align:center;
    border: 1px solid #DCDCDC;
    background: transparent;
    width: 50px;
    height: 50px;
  }
 
  input:valid
  {
    border: 1px solid #4F4F4F;
    background: transparent;
    box-shadow: #DCDCDC 0px 0px 0px 1px
  }

  input:focus
  {
    outline:none;
    box-shadow:none;
    border-color: #4F4F4F;
    border-width: 2px;
  }

  input[type='password'] {
    font-family: Verdana;
    letter-spacing: 0.125em;
  }

  input {
    height: 3.5rem;
  }
}


.pincode-input-container-popup.invalid {
  input {
    border-color: #FD85A4;
    border-width: 2px;
  }
}

.pin-popup .user-card-item {
  width: 100%;
  padding: 0 15px;
}